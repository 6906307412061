@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply font-primary text-primary;
  }

  @font-face {
    font-family: "GillSansCondC";
    src: url("fonts/GillSansC/GillSansCondC-Bold.eot");
    src: local("fonts/GillSansC/GillSansCondC Bold"),
      local("fonts/GillSansC/GillSansCondC-Bold"),
      url("fonts/GillSansC/GillSansCondC-Bold.eot?#iefix")
        format("embedded-opentype"),
      url("fonts/GillSansC/GillSansCondC-Bold.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansCondC-Bold.woff") format("woff"),
      url("fonts/GillSansC/GillSansCondC-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "GillSansCondBlackC";
    src: url("fonts/GillSansC/GillSansCondBlackC.eot");
    src: local("fonts/GillSansC/GillSansCondBlackC"),
      url("fonts/GillSansC/GillSansCondBlackC.eot?#iefix")
        format("embedded-opentype"),
      url("fonts/GillSansC/GillSansCondBlackC.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansCondBlackC.woff") format("woff"),
      url("fonts/GillSansC/GillSansCondBlackC.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
  }

  @font-face {
    font-family: "GillSansCondC";
    src: url("fonts/GillSansC/GillSansCondC.eot");
    src: local("fonts/GillSansC/GillSansCondC"),
      url("fonts/GillSansC/GillSansCondC.eot?#iefix")
        format("embedded-opentype"),
      url("fonts/GillSansC/GillSansCondC.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansCondC.woff") format("woff"),
      url("fonts/GillSansC/GillSansCondC.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "GillSansC";
    src: url("fonts/GillSansC/GillSansC-Italic.eot");
    src: local("fonts/GillSansC/GillSansC Italic"),
      local("fonts/GillSansC/GillSansC-Italic"),
      url("fonts/GillSansC/GillSansC-Italic.eot?#iefix")
        format("embedded-opentype"),
      url("fonts/GillSansC/GillSansC-Italic.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansC-Italic.woff") format("woff"),
      url("fonts/GillSansC/GillSansC-Italic.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
  }

  @font-face {
    font-family: "GillSansC";
    src: url("fonts/GillSansC/GillSansC-BoldItalic.eot");
    src: local("fonts/GillSansC/GillSansC Bold Italic"),
      local("fonts/GillSansC/GillSansC-BoldItalic"),
      url("fonts/GillSansC/GillSansC-BoldItalic.eot?#iefix")
        format("embedded-opentype"),
      url("fonts/GillSansC/GillSansC-BoldItalic.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansC-BoldItalic.woff") format("woff"),
      url("fonts/GillSansC/GillSansC-BoldItalic.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
  }

  @font-face {
    font-family: "GillSansC";
    src: url("fonts/GillSansC/GillSansC.eot");
    src: local("fonts/GillSansC/GillSansC"),
      url("fonts/GillSansC/GillSansC.eot?#iefix") format("embedded-opentype"),
      url("fonts/GillSansC/GillSansC.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansC.woff") format("woff"),
      url("fonts/GillSansC/GillSansC.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: "GillSansExtraBoldC";
    src: url("fonts/GillSansC/GillSansExtraBoldC.eot");
    src: local("fonts/GillSansC/GillSansExtraBoldC"),
      url("fonts/GillSansC/GillSansExtraBoldC.eot?#iefix")
        format("embedded-opentype"),
      url("fonts/GillSansC/GillSansExtraBoldC.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansExtraBoldC.woff") format("woff"),
      url("fonts/GillSansC/GillSansExtraBoldC.ttf") format("truetype");
    font-weight: 800;
    font-style: normal;
  }

  @font-face {
    font-family: "GillSansC";
    src: url("fonts/GillSansC/GillSansC-Bold.eot");
    src: local("fonts/GillSansC/GillSansC Bold"),
      local("fonts/GillSansC/GillSansC-Bold"),
      url("fonts/GillSansC/GillSansC-Bold.eot?#iefix")
        format("embedded-opentype"),
      url("fonts/GillSansC/GillSansC-Bold.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansC-Bold.woff") format("woff"),
      url("fonts/GillSansC/GillSansC-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
  }

  @font-face {
    font-family: "GillSansLightC";
    src: url("fonts/GillSansC/GillSansLightC.eot");
    src: local("fonts/GillSansC/GillSansLightC"),
      url("fonts/GillSansC/GillSansLightC.eot?#iefix")
        format("embedded-opentype"),
      url("fonts/GillSansC/GillSansLightC.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansLightC.woff") format("woff"),
      url("fonts/GillSansC/GillSansLightC.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
  }

  @font-face {
    font-family: "GillSansLightC";
    src: url("fonts/GillSansC/GillSansLightC-Italic.eot");
    src: local("fonts/GillSansC/GillSansLightC Italic"),
      local("fonts/GillSansC/GillSansLightC-Italic"),
      url("fonts/GillSansC/GillSansLightC-Italic.eot?#iefix")
        format("embedded-opentype"),
      url("fonts/GillSansC/GillSansLightC-Italic.woff2") format("woff2"),
      url("fonts/GillSansC/GillSansLightC-Italic.woff") format("woff"),
      url("fonts/GillSansC/GillSansLightC-Italic.ttf") format("truetype");
    font-weight: 300;
    font-style: italic;
  }
}
